<template>
  <!-- 我的考试 -->
  <div class="quesTion">
    <kind-choose-view :show-kc="true" :type="7" :is-que="true" cookies-is-ok="isOk" :entrants-type="3"
      :test-paper-type="testPaperType" @isOk="isOk" />
    <div style="padding-bottom: 20px;" class="qustionList">
      <!-- 试卷包 -->
      <div v-if="list && list.length > 0 && search.examType == 1" class="quesTion-car">
        <div class="dis_flex_between">
          <div style="background: #ffffff;width: 71%;border-radius: 8px;">
            <!-- <div class="tab_box">
              <div 
                class="btn_item"
                :class="[!search.type ? 'on' : '']"
                @click="changeTabs()"
              >
                全部
              </div>
              <div
                v-for="(item, index) in search.classfiList"
                v-show="item.fName != '随堂练习'"
                :key="index"
                class="btn_item"
                :class="[search.type == item.fId ? 'on' : '']"
                @click="changeTabs(item.fId)"
              >
                {{ item.fName }}
              </div>
            </div> -->
            <div v-for="(item, index) in list" :key="index" class="item page">
              <img style="width: 90px; height: 90px" class="item-img examPage" src="@/assets/img/partner/paper1.png">
              <div class="item-content" style="flex: 1">
                <div style="display: flex; align-items: center; justify-content:space-between;">
                  <div class="item-title twoEllipsis">
                    {{ item.name }}
                  </div>
                  <div style="font-size: 12px;font-family: PingFang SC, PingFang SC;font-weight: 400;color: #A5ACB8;">{{
                    item.clicks }} 浏览 </div>
                </div>

                <div class="dis_flex_between" style="align-items: center; margin-top: 22px">
                  <div class="dis_flex_start" style="align-items: center">
                    <i class="el-icon-document"></i>
                    <div class="paper_list">
                      <el-dropdown trigger="click">
                        <div style="display: flex; align-items: center;" class="el-dropdown-link">
                          <span class="elp" style="
                              display:block;
                              max-width: 400px !important;
                              margin-right: 5px;
                              /* vertical-align: middle; */
                            ">
                            {{ item.paperNameOne }}
                          </span>
                          共<span style="color: #0270e8;">{{ item.paperNameList ?
                            item.paperNameList.length : 0 }}</span>
                          套试卷
                          <i class="el-icon-arrow-down el-icon--right" />
                        </div>
                        <el-dropdown-menu slot="dropdown" style="max-width: 480px; padding: 0 20px">
                          <el-dropdown-item v-for="(items, indexs) in item.paperNameList" :key="indexs"
                            class="paper_item elp">
                            <div class="elp" style="max-width: 480px">
                              {{ items.name }}
                            </div>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </div>

                  <div class="item-but">
                    <div v-if="university != 1" class="colleges-price">
                      <span class="price-icon">￥</span>{{ item.packagePrice }}
                    </div>
                    <div class="btn" @click="goPage(item)">查看</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <class-push :search="search" class="youlove">其他课程推荐</class-push>
        </div>
      </div>
      <!-- 试卷  -->
      <div v-if="list && list.length > 0 && search.examType == 2" class="quesTion-car">
        <div class="dis_flex_between">
          <div>
            <div v-for="(item, index) in list" :key="index" class="item">
              <div class="flex-jc-c flex-c" style="width: 90px; height: 90px; margin-right: 12px">
                <img style="width: 54px; height: 54px" class="examPage" src="@/assets/img/partner/papers1.png">
              </div>
              <div class="item-content">
                <div class="item-title twoEllipsis">{{ item.name }}</div>
                <div class="item-but">
                  <div v-if="university != 1" class="colleges-price">
                    <span class="price-icon">￥</span>{{ item.paperPrice }}
                  </div>
                  <div v-if="item.paperPrice > 0 && item.status == 2">
                    <div v-if="!item.seeNum" class="colleges-learn" @click="goOrder(12, item)">
                      立即购买
                    </div>
                    <div v-else class="colleges-learn" @click="goLearn(item, false)">
                      免费试看
                    </div>
                  </div>
                  <div v-else class="colleges-learn" @click="goLearn(item, true)">
                    马上做题
                  </div>
                </div>
              </div>
            </div>
          </div>
          <class-push :search="search" class="youlove">其他课程推荐</class-push>
        </div>
      </div>

      <!-- 暂无课程 -->
      <div v-if="!list || list.length == 0" class="nothing">
        <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
        <div class="nothing-text">暂无内容哦~</div>
      </div>
      <div class="flex-c" style="margin: 30px calc(50% - 420px) 0">
        <button class="pageBtn" @click="toFirstPage">首页</button>
        <el-pagination class="pagination partner-pagination" style="margin: 0 !important" prev-text="上一页"
          next-text="下一页" layout="prev, pager, next" :total="total" :current-page="pageNum" :page-size="pageSize"
          :pager-count="5" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
        <button class="pageBtn" @click="toLastPage">末页</button>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { getDomain } from '@/api/cookies'

import { getInfo, getToken } from '@/api/cookies'
import KindChooseView from '@/components/kindChooseViewsSeventh.vue'
import { OrderClient } from '@/api/orderClient'
import classPush from '@/components/Know/classPush.vue'
const orderClient = new OrderClient()
import { AnswerClient } from '@/api/task/answerClient'
const answerClient = new AnswerClient()
export default {
  components: { KindChooseView, classPush },

  data() {
    return {
      list: [], // 查询列表
      getInfo: null,
      search: {
        examType: 1
      },
      pageSize: 9,
      pageNum: 1,
      total: 0,

      /* 考点讲解显示 */
      ifPlace: false,
      isQue: false,
      thisList: null,
      testPaperType: null,
      domainInfo: {},
      university: ''
    }
  },
  async created() {
    this.domainInfo = getDomain()
    if (this.domainInfo.eId == process.env.VUE_APP_university) {
      this.university = 1
    }
    this.testPaperType = this.$route.query.type
    this.userInfo = getInfo()
    this.getList()
  },
  methods: {
    /* 获取列表 */
    getList() {
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: '',
        background: 'rgba(0, 0, 0, 0.2)'
      })
      if (this.search.type == 4002) {
        /* 考点练习 */
        answerClient
          .getPcExamPlaceList(
            this.pageSize,
            this.pageNum,
            this.search.kind ? this.search.kind : undefined,
            this.search.zyId ? this.search.zyId : undefined,
            this.search.kcId,
            undefined,
            this.userInfo ? this.userInfo.id : undefined
          )
          .then((res) => {
            this.list = res.rows
            this.total = res.total
            loading.close()
          })
      } else {
        if (this.search.examType == 1) {
          /* 试卷包 */
          answerClient
            .selectPageList(
              this.pageSize,
              this.pageNum,
              this.search.kind ? this.search.kind : undefined,
              // this.search.stageName ? this.search.stageName : undefined,
              this.search.zyId ? this.search.zyId : undefined,
              this.search.type ? this.search.type : undefined,
              this.userInfo ? this.userInfo.id : undefined,
              this.search.kcId,
              this.search.name
            )
            .then((res) => {
              console.log(res)
              if (res.code) {
                this.list = []
                this.total = 0
              } else {
                res.rows.forEach((e) => {
                  if (e.paperNameList && e.paperNameList.length > 0) {
                    e.paperNameOne = e.paperNameList[0].name
                  } else {
                    e.paperNameOne = ''
                  }

                })
                this.list = res.rows
                this.total = res.total
              }
              loading.close()
            })
        } else {
          const params = {
            pageSize: this.pageSize,
            pageNum: this.pageNum,
            kindId: this.search.kind ? this.search.kind : undefined,
            zStage: this.search.stageName ? this.search.stageName : undefined,
            majorId: this.search.zyId ? this.search.zyId : undefined,
            typeId: this.search.type ? this.search.type : undefined,
            userId: this.userInfo ? this.userInfo.id : undefined,
            isScreen: 1,
            courseId: this.search.kcId,
            name: this.search.name
          }
          /* 试卷 */
          answerClient.getNewQuestionPaperList(params).then((res) => {
            if (res.code) {
              this.list = []
              this.total = 0
            } else {
              this.list = res.rows
              this.total = res.total
            }
            loading.close()
          })
        }
      }
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList()
    },
    /* --------------------搜索--------------------------------- */
    // 筛选
    isOk(val) {
      this.search = val
      console.log(val)
      // this.getList()
      this.searchClick()
    },
    // 前往首页
    toFirstPage() {
      this.handleCurrentChange(1)
    },

    // 前往尾页
    toLastPage() {
      const max = Math.ceil(this.total / this.pageSize)
      this.handleCurrentChange(max)
    },
    /* 搜索 */
    searchClick() {
      this.pageNum = 1
      this.searc
      this.list = []
      this.total = 0
      this.getList()
    },
    /* --------------------购买--------------------------------- */

    // 立即购买
    goOrder(type, item) {
      const token = getToken()
      if (token) {
        orderClient
          .addOrders(
            item.id,
            localStorage.getItem('userId'),
            item.name,
            type,
            item.name,
            item.kind
          )
          .then((res) => {
            if (res.code == 0) {
              this.$router.push({
                name: '个人中心订单页',
                params: { orderNumber: res.msg }
              })
            } else {
              this.$message.error(res.msg)
            }
          })
      } else {
        Vue.prototype.goLoginView(true)
        // this.$router.push({ name: "登录页", params: { hzc: 1 } });
      }
    },
    /* 试卷bao做题 */
    goPage(item) {
      if (!this.userInfo) {
        this.$notify({
          title: '错误',
          message: '未登录，无法做题, 请先去登录',
          type: 'error',
          duration: 2000
        })
        return
      }
      this.$router.push(`/all/examPackPartner?packId=${item.id}`)
    },
    /* 试卷做题 */
    /* 做题 */
    goLearn(item, ifHave) {
      Vue.prototype.goExamPartner(item.name, item.id, 4, 12, ifHave, item.seeNum)
    },
    /* 进入考点*/
    goPlace(item) {
      if (!this.userInfo) {
        Vue.prototype.goLoginView
        return
      }
    }
  }
}
</script>
<style lang="less" scoped>
.tab_box {
  width: 412px;
  height: 38px;
  border-radius: 6px;
  padding: 4px;
  background-color: #f5f7f9;
  display: flex;
  justify-content: space-around;
  position: relative;
  margin: 10px;

  .btn_item {
    width: 132px;
    height: 30px;
    line-height: 30px;
    border-radius: 6px;
    font-size: 14px;
    text-align: center;
    color: #333333;
    position: relative;
    z-index: 1;
    cursor: pointer;
  }

  .on {
    color: #4a6af0;
    font-weight: 800;
    background: #ffffff;
  }
}

.quesTion-car {
  width: 1200px;
  display: block;

  .item {
    width: 852px;
    height: 130px;
    margin-bottom: 0;
    border-bottom: 1px solid #eeeeee;
    padding: 20px 20px 20px 30px;

    .item-title {
      height: 22px;
      margin-top: 10px;
      max-width: 590px;
      line-height: 22px;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }

    .paper_list {
      max-width: 500px;
      height: 20px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #a5acb8;
    }

    .item-but {
      display: flex;
      align-items: center;
      margin-top: 0px;
    }

    .colleges-price {
      height: 24px;
      line-height: 24px;
      font-size: 18px;
      margin-right: 21px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #fb2d25;
    }

    .item-tips {
      margin: 0 10px;
    }

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 94px;
      height: 36px;
      background: #ffffff;
      border-radius: 18px 18px 18px 18px;
      opacity: 1;
      border: 1px solid #cccccc;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  }

  .item:last-child {
    border-bottom: 0;
  }
}

.price-icon {
  font-size: 14px;
}
</style>
